import { CONFIG } from './config'

//Valid combinations of characters that form the language orthography
export const ORTHOGRAPHY = [
  'a',
  'ā',
  'á',
  'ǎ',
  'à',
  'b',
  'c',
  'd',
  'e',
  'ē',
  'é',
  'ě',
  'è',
  'f',
  'g',
  'h',
  'i',
  'ī',
  'í',
  'ǐ',
  'ì',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'ō',
  'ó',
  'ǒ',
  'ò',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'ū',
  'ú',
  'ǔ',
  'ù',
  'ü',
  'ǖ',
  'ǘ',
  'ǚ',
  'ǜ',
  'v',
  'w',
  'x',
  'y',
  'z',
]

//Symbols on the main keyboard
export const KEY_SYMBOLS = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'ü',
  'v',
  'w',
  'x',
  'y',
  'z',
]

// Combining marks such as diacritics and suffixes
export const COMBINING_MARKS = [
  '\u0304',
  '\u0301',
  '\u030C',
  '\u0300',
  '\u0308',
]

if (CONFIG.normalization) {
  ORTHOGRAPHY.forEach(
    (val, i) => (ORTHOGRAPHY[i] = val.normalize(CONFIG.normalization))
  )
  KEY_SYMBOLS.forEach(
    (val, i) => (KEY_SYMBOLS[i] = val.normalize(CONFIG.normalization))
  )
  COMBINING_MARKS.forEach(
    (val, i) => (COMBINING_MARKS[i] = val.normalize(CONFIG.normalization))
  )
}
