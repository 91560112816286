import { CONFIG } from './config'

export const WORDS = [
"jìlù",
"zǔhé",
"zìjǐ",
"yùxí",
"yùjì",
"yìyì",
"yīqǐ",
"yěxǔ",
"yālì",
"xīyī",
"xībù",
"wǔqì",
"tǐyù",
"tímù",
"tèsè",
"sùdù",
"sījī",
"rúhé",
"rìqī",
"rèài",
"qǔdé",
"qítā",
"qícì",
"pǔjí",
"nǔlì",
"nàlǐ",
"mùdì",
"mǎlù",
"lǐwù",
"lìrú",
"lìkè",
"kěyǐ",
"kèqì",
"kěpà",
"kělè",
"kējì",
"kèfú",
"kěài",
"jùtǐ",
"jìxù",
"jítǐ",
"jīqì",
"jījí",
"hélǐ",
"hégé",
"héfǎ",
"gùyì",
"gùkè",
"gèzì",
"gēmí",
"gèdì",
"fùzé",
"fùzá",
"fùxí",
"fúwù",
"fùmǔ",
"fādá",
"dìtú",
"dìqū",
"dàyī",
"dàfū",
"cíyǔ",
"bùrú",
"bùdà",
"bùbì",
"bùān",
"bìxū",
"bǐrú",
"bǐlì",
"bǐjì",
"bǎwò",
"jiào",
"fàng",
"yuàn",
"qǐng",
"míng",
"jiàn",
"bìng",
"biàn",
"zhàn",
"xíng",
"xiǎo",
"wǎng",
"tīng",
"shōu",
"shǒu",
"jiāo",
"dòng",
"zhǔn",
"zhuā",
"zhēn",
"zhào",
"yuán",
"xìng",
"xiān",
"tíng",
"tiào",
"sòng",
"shuō",
"shuì",
"shòu",
"qián",
"píng",
"pèng",
"néng",
"miàn",
"máng",
"lǐng",
"kuài",
"jiǎo",
"guān",
"gōng",
"fēng",
"duàn",
"diào",
"diàn",
"děng",
"chǎo",
"bāng",
"zǒng",
"zīge",
"zhuī",
"zhōu",
"zhèr",
"zhǎo",
"zàng",
"yuǎn",
"yòng",
"yǐzi",
"yìsi",
"yíng",
"yīfu",
"yéye",
"yèli",
"yǎng",
"yáng",
"xuǎn",
"xiào",
"xiàn",
"wūzi",
"wánr",
"wàng",
"tuán",
"tōng",
"tòng",
"tǐng",
"tiáo",
"tiān",
"tián",
"téng",
"tāng",
"táng",
"suàn",
"shuǐ",
"shēn",
"shéi",
"shǎo",
"shān",
"rìzi",
"réng",
"ràng",
"quán",
"qīng",
"qíng",
"qiǎo",
"qiáo",
"qiān",
"piào",
"piān",
"piàn",
"pàng",
"nòng",
"niǎo",
"niàn",
"nián",
"nàme",
"nǎli",
"māma",
"luàn",
"lóng",
"lìzi",
"líng",
"liǎn",
"liàn",
"lián",
"lěng",
"kùzi",
"kōng",
"jùzi",
"jìng",
"jiān",
"huàn",
"huán",
"huài",
"hóng",
"hāha",
"guǎn",
"gèzi",
"gèng",
"gēge",
"gāng",
"fáng",
"érzi",
"duǎn",
"dōng",
"dǒng",
"dìng",
"dìdi",
"diǎn",
"dēng",
"dǎpo",
"dāng",
"dàda",
"cóng",
"chuī",
"chāo",
"cháo",
"céng",
"biǎo",
"biān",
"bàba",
"jian",
]

if (CONFIG.normalization) {
  WORDS.forEach((val, i) => (WORDS[i] = val.normalize(CONFIG.normalization)))
}

function shuffle(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

if (CONFIG.shuffle) {
  shuffle(WORDS)
}
